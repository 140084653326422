<template>
  <el-button
    v-if="hasPermission('tickets.change_ticket')"
    :loading="loading"
    type="danger"
    @click="confirmUnbindTicket()"
  >
    Usuń bilet z konta użytkownika
  </el-button>
</template>
<script>
import { User } from '@/api'

export default {
  props: {
    ticketId: String,
    userId: [String, Number]
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    confirmUnbindTicket() {
      const msg =
        'Potwierdzenie operacji spowoduje usunięcie biletu z konta użytkownika. Bilet stanie się bezimienny.'
      this.$confirm(msg, 'Ostrzeżenie', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Anuluj',
        type: 'warning'
      }).then(() => {
        this.unbindTicket()
      })
    },
    unbindTicket() {
      this.loading = true
      const lookupParams = {
        id: this.userId
      }
      const payload = {
        ticket: this.ticketId
      }
      User.unbindUserFromTicket(lookupParams, payload)
        .then(() => {
          this.$emit('reload')

          const message = 'Usunięto bilet z konta użytkownika.'
          this.$message({
            message: message,
            type: 'success'
          })
        })
        .catch(error => {
          const message =
            'Wystąpił błąd podczas usuwania biletu z konta użytkownika.'
          this.$message({
            message: message,
            type: 'error'
          })
          Object.values(error.response.data).forEach(value => {
            const errorMessage = Array.isArray(value) ? value.join(' ') : value
            this.$message({
              message: errorMessage,
              type: 'error'
            })
          })
          this.$emit('reload') // maybe other admin in the same time has edited user profile
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
