<template>
  <el-row type="flex" align="middle" class="wrapper">
    <p class="filter-header"><i class="el-icon-s-operation" /> Filtruj:</p>

    <el-col>
      <el-row>
        <el-col :sm="24" :md="16" :lg="20">
          <el-row type="flex" :gutter="16">
            <el-col :sm="24" :md="8" :lg="7" class="filter">
              <span>Typ użytkownika:</span>
              <el-select v-model="profile" placeholder="Wybierz typ">
                <el-option label="Wszyscy" value="" />
                <el-option
                  v-for="item in $store.state.profiles"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :sm="24" :md="8" :lg="7" class="filter">
              <span>Data:</span>
              <el-date-picker
                v-model="dateRange"
                class="picker"
                type="daterange"
                range-separator="-"
                start-placeholder="Data od"
                end-placeholder="Data do"
              >
              </el-date-picker>
            </el-col>
          </el-row>
        </el-col>

        <el-col :sm="24" :md="8" :lg="4">
          <el-input
            v-model="search"
            prefix-icon="el-icon-search"
            placeholder="Wpisz frazę - np. nr biletu"
          />
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
export default {
  queryModels: ['profile', 'search', 'date_from', 'date_to'],
  computed: {
    dateRange: {
      set(value) {
        if (!value) {
          this.date_from = undefined
          this.date_to = undefined
        } else {
          this.date_from = value[0].toISOString()
          const dateTo = value[1]
          dateTo.setHours(23, 59, 59)
          this.date_to = dateTo.toISOString()
        }
      },
      get() {
        return [this.date_from, this.date_to]
      }
    }
  },
  watch: {
    '$store.state.profiles': {
      immediate: true,
      handler(value) {
        if (value.length && this.profile === undefined) {
          this.profile = value[0].value
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/styles/filters';

.picker {
  display: block !important;
}
</style>
