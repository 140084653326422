<template>
  <el-dialog
    title="Historia wejść/wyjść na bramce"
    width="50vw"
    append-to-body
    :visible.sync="dialogVisible"
    @close="onClose"
  >
    <gate-entry-logs-table v-loading="loading" :results="logs" />
    <request-gate-entry-log-synchronization-button :ticket-id="ticketId" />
  </el-dialog>
</template>

<script>
import { GateEntryLog } from '@/api'
import GateEntryLogsTable from '@/components/tables/GateEntryLogsTable'
import RequestGateEntryLogSynchronizationButton from '@/components/buttons/RequestGateEntryLogSynchronizationButton'

export default {
  components: { RequestGateEntryLogSynchronizationButton, GateEntryLogsTable },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      logs: [],
      ticketId: null
    }
  },
  methods: {
    showModal(ticketId) {
      this.dialogVisible = true
      this.ticketId = ticketId
      this.fetchData()
    },
    fetchData() {
      this.loading = true
      const params = { ticket: this.ticketId, page_size: 'max' }
      GateEntryLog.fetch({}, { params })
        .then(res => {
          this.logs = res.data.results
        })
        .finally(() => {
          this.loading = false
        })
    },
    onClose() {
      this.ticketId = null
      this.logs = []
    }
  }
}
</script>

<style scoped></style>
