<template>
  <el-row type="flex" align="middle" class="wrapper">
    <p class="filter-header"><i class="el-icon-s-operation" /> Filtruj:</p>

    <el-input
      v-model="search"
      prefix-icon="el-icon-search"
      placeholder="Wpisz frazę"
    />
  </el-row>
</template>

<script>
import { Ticket } from '@/api'

export default {
  queryModels: [
    'status',
    'search',
    'source',
    'has_active_qr_code',
    'ticket_type'
  ],
  data() {
    return {
      availableSources: [],
      availableStatuses: [],
      availableTypes: []
    }
  },
  created() {
    this.fetchAvailableSources()
    this.fetchAvailableStatuses()
    this.fetchAvailableTypes()
  },
  methods: {
    fetchAvailableSources() {
      Ticket.availableSources()
        .then(response => {
          this.availableSources = response.data
        })
        .catch(error => {
          console.error(error)
        })
    },
    fetchAvailableStatuses() {
      Ticket.availableStatuses()
        .then(response => {
          this.availableStatuses = response.data
        })
        .catch(error => {
          console.error(error)
        })
    },
    fetchAvailableTypes() {
      Ticket.availableTypes()
        .then(response => {
          this.availableTypes = response.data
        })
        .catch(error => {
          console.error(error)
        })
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/styles/filters';
</style>
