<template>
  <div class="box">
    <add-q-r-code-modal ref="addQRCodeModal" @refresh="() => fetchUser()" />
    <ticket-logs-modal ref="ticketLogsModal" />
    <gate-entry-logs-modal ref="gateEntryLogsModal" />

    <page-header :title="user.full_name || ''" />
    <el-tabs v-model="selectedTab" v-loading="loading">
      <el-tab-pane label="Ogólne" name="info">
        <info :user="user" @change="fetchUser" />
      </el-tab-pane>
      <el-tab-pane label="Bilety" name="tickets">
        <tickets
          :user="user"
          @open-add-qr-code-modal="openAddQRCodeModal"
          @open-history-modal="openHistoryModal"
          @open-gate-logs-modal="openGateLogsModal"
        />
      </el-tab-pane>
      <el-tab-pane label="Bilety dla dziecka" name="child_tickets"
        ><child-tickets
          :user="user"
          @open-add-qr-code-modal="openAddQRCodeModal"
          @open-history-modal="openHistoryModal"
          @open-gate-logs-modal="openGateLogsModal"
      /></el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { User } from '@/api'
import PageHeader from '@/layouts/PageHeader'
import Info from './Info'
import ChildTickets from './ChildTickets'
import Tickets from './Tickets'
import GateEntryLogsModal from '@/components/modals/GateEntryLogsModal'
import TicketLogsModal from '@/components/modals/TicketLogsModal'
import AddQRCodeModal from '@/components/modals/AddQRCodeModal/index.vue'

export default {
  components: {
    GateEntryLogsModal,
    PageHeader,
    Tickets,
    ChildTickets,
    TicketLogsModal,
    AddQRCodeModal,
    Info
  },
  data() {
    return {
      selectedTab: 'info',
      user: { tickets: [] },
      loading: false
    }
  },
  created() {
    this.fetchUser()
  },
  methods: {
    openAddQRCodeModal(ticket) {
      this.$refs.addQRCodeModal.showModal(ticket)
    },
    openHistoryModal(ticketId) {
      this.$refs.ticketLogsModal.showModal(ticketId)
    },
    openGateLogsModal(ticketId) {
      this.$refs.gateEntryLogsModal.showModal(ticketId)
    },
    fetchUser() {
      this.loading = true
      User.get(this.$route.params.id)
        .then(res => {
          this.user = res.data
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
