<template>
  <el-dialog
    v-if="ticket"
    title="Identyfikator QR"
    :center="true"
    width="50vw"
    append-to-body
    :visible.sync="dialogVisible"
    @close="onClose"
  >
    <el-form class="qr-code-form">
      <el-form-item label="Imię i naziwsko">
        <el-input v-model="ticket.owner_name" :disabled="true" />
      </el-form-item>
      <el-form-item label="Numer biletu">
        <el-input v-model="ticket.ticket_id" :disabled="true" />
      </el-form-item>
      <el-form-item label="Data wydarzenia">
        <el-date-picker
          v-model="ticket.date"
          :disabled="true"
          class="fluid"
          format="dd.MM.yyyy"
        />
      </el-form-item>
      <el-form-item label="Typ biletu">
        <el-input v-model="ticket.ticket_type_display" :disabled="true" />
      </el-form-item>
      <div v-if="siteSettings.is_health_questionnaire_required !== undefined">
        <health-questionnaire-form
          v-if="
            isHealthQuestionnaireRequired && hasPermission('tickets.add_qrcode')
          "
          :ticket-id="ticket.ticket_id"
          :ticket="ticket"
          @cancel="onClose"
          @submit="onSubmit"
        />
        <generate-q-r-code-form
          v-else-if="hasPermission('tickets.add_qrcode')"
          generate-button-label="Wygeneruj"
          :ticket-id="ticket.ticket_id"
          :active-q-r-code="ticket.active_qr_code"
          :show-cancel-button="true"
          @cancel="onClose"
          @submit="onSubmit"
        />
      </div>
      <el-card v-else v-loading="true"></el-card>
    </el-form>
  </el-dialog>
</template>

<script>
import HealthQuestionnaireForm from '@/components/forms/HealthQuestionnaireForm'
import GenerateQRCodeForm from '@/components/forms/GenerateQRCodeForm'

export default {
  components: { GenerateQRCodeForm, HealthQuestionnaireForm },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      ticket: null
    }
  },
  computed: {
    siteSettings() {
      return this.$store.state.settings
    },
    isHealthQuestionnaireRequired() {
      return (
        !this.ticket.health_questionnaire &&
        this.siteSettings.is_health_questionnaire_required
      )
    }
  },
  created() {
    this.$store.dispatch('fetchSettings')
  },
  methods: {
    showModal(ticket) {
      this.dialogVisible = true
      this.ticket = ticket
    },
    onClose() {
      this.dialogVisible = false
      this.ticket = null
    },
    onSubmit() {
      this.dialogVisible = false
      this.$emit('refresh')
      this.$message.success('Wygenerowano kod QR.')
    }
  }
}
</script>

<style scoped></style>
