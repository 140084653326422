import VueRouter from 'vue-router'

import Login from '@/views/Login'
import NotFound from '@/views/404'
import Home from '@/views/Home'

const getView = view => require(`@/views/${view}`).default

let routes = [
  {
    path: '/login',
    component: Login,
    name: 'Login',
    hidden: true,
    meta: {
      guests: true
    }
  },
  {
    path: '/404',
    component: NotFound,
    name: '404',
    hidden: true,
    meta: {
      guests: true
    }
  },
  {
    path: '/',
    component: getView('HomePage/HomePage'),
    name: 'HomePage',
    iconCls: 'el-icon-star-on',
    meta: { label: 'HomePage' },
    hidden: true
  },
  {
    path: '/users',
    component: Home,
    iconCls: 'fa fa-group',
    meta: { label: 'Użytkownicy' },
    children: [
      {
        path: '',
        component: getView('User/UserList/'),
        name: 'User List',
        meta: {
          label: 'Lista użytkowników',
          permission: 'permissions.view_users_list'
        }
      },
      {
        path: 'add',
        component: getView('User/UserForm/'),
        name: 'Add User',
        meta: { label: 'Dodaj użytkownika', permission: 'users.add_user' }
      },
      {
        path: 'details/:id',
        props: true,
        component: getView('User/UserDetails/'),
        name: 'User Details',
        hidden: true,
        meta: { label: 'Szczegóły użytkownika', permission: 'users.view_user' }
      },
      {
        path: ':id',
        props: true,
        component: getView('User/UserForm/'),
        name: 'Edit User',
        hidden: true,
        meta: { label: 'Edytuj użytkownika', permission: 'users.change_user' }
      }
    ]
  },
  {
    path: '/settings',
    component: Home,
    iconCls: 'fa fa-cog',
    meta: { label: 'Ustawienia' },
    children: [
      {
        path: 'site',
        component: getView('Settings/SettingsForm'),
        name: 'Settings Form',
        meta: {
          label: 'Konfiguracja strony',
          permission: 'core.change_sitesettings'
        }
      }
    ]
  },
  {
    path: '/tickets',
    component: Home,
    iconCls: 'fa fa-desktop',
    meta: { label: 'Bilety' },
    children: [
      {
        path: '',
        component: getView('Tickets/TicketList'),
        name: 'Tickets List',
        meta: { label: 'Lista biletów', permission: 'tickets.view_ticket' }
      },
      {
        path: ':id',
        props: true,
        component: getView('Tickets/TicketDetails'),
        name: 'Ticket Details',
        hidden: true,
        meta: { label: 'Przeglądaj bilet', permission: 'tickets.view_ticket' }
      }
    ]
  },
  {
    path: '/tickets-logs',
    component: Home,
    iconCls: 'fa fa-eye',
    meta: { label: 'Aktywności' },
    children: [
      {
        path: '',
        component: getView('TicketLog/TicketLogList/'),
        name: 'Ticket Log List',
        meta: {
          label: 'Lista aktywności',
          permission: 'ticket_logs.view_ticketlog'
        }
      }
    ]
  },
  {
    path: '/clearing-point/tickets',
    component: Home,
    iconCls: 'fa fa-desktop',
    meta: { label: 'Bilety (clearingpoint)' }, // @todo change name
    children: [
      {
        path: '',
        component: getView('ClearingPoint/TicketsList/index'),
        name: 'ClearingPoint Tickets List',
        meta: {
          label: 'Lista biletów',
          permission: 'permissions.clearingpoint_tickets'
        }
      }
    ]
  },
  {
    path: '*',
    hidden: true,
    redirect: { path: '/404' }
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const isUserLoggedIn = window.localStorage.getItem('token')
  if (!to.meta.guests && !isUserLoggedIn) {
    next({ name: 'Login', query: { next: to.path } })
    return
  }
  document.title = to.meta.label
    ? `${to.meta.label} - Panel administracyjny`
    : 'Panel administracyjny'
  next()
})

export default router
