<script>
import { SiteSettings } from '@/api/'
import FormMixin from '@/crudBases/form/FormMixin'
import fields from '@/components/fields/'
import { cloneDeep } from 'lodash'

export default {
  components: {},
  mixins: [FormMixin],
  computed: {
    service() {
      return SiteSettings
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.service.get().then(response => {
        this.form = response.data
        this.prepareFormCopyToPreventChangesLost()
      })
    },
    onSubmit() {
      this.loading = true
      const service = this.service.update(this.form)
      service
        .then(this.successHandler)
        .then(() => {
          this.formCopy = cloneDeep(this.form)
        })
        .catch(this.errorHandler)
    },
    getFields() {
      return [
        new fields.Switch({
          label: 'Wymagaj ankiet',
          modelPath: [this, 'form', 'is_health_questionnaire_required']
        })
      ]
    }
  }
}
</script>
