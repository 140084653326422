<template>
  <el-row type="flex" align="middle" class="wrapper">
    <p class="filter-header"><i class="el-icon-s-operation" /> Filtruj:</p>

    <el-col>
      <el-row>
        <el-col :sm="24" :md="16" :lg="20">
          <el-row type="flex" :gutter="16">
            <el-col :sm="24" :md="8" :lg="7" class="filter">
              <span>Rola:</span>
              <el-select
                v-model="profile"
                placeholder="Wybierz rolę"
                :clearable="true"
              >
                <el-option label="Wszyscy" value="" />
                <el-option
                  label="Wszyscy administratorzy panelu"
                  value="all_profiles"
                />
                <el-option
                  v-for="item in $store.state.profiles"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>

        <el-col :sm="24" :md="8" :lg="4">
          <el-input
            v-model="search"
            prefix-icon="el-icon-search"
            placeholder="Wpisz frazę"
          />
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
export default {
  queryModels: ['profile', 'search'],
  watch: {
    '$store.state.profiles': {
      immediate: true,
      handler(value) {
        if (value.length && this.profile === undefined) {
          const profile = value.find(item => item.type === 'HUB')
          this.profile = profile?.value || value[0].value
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/styles/filters';
</style>
