<template>
  <div v-if="tickets && tickets.length" class="box">
    <div
      class="el-table list__table el-table--fit el-table--enable-row-hover el-table--enable-row-transition"
    >
      <table class="table table-striped" style="width: 100%">
        <thead class="thead-dark">
          <tr>
            <th>lp.</th>
            <th>numer biletu</th>
            <th>status</th>
            <th>ankieta wypełniona</th>
            <th>aktywny QRCode</th>
            <th>imię i nazwisko właściciela biletu</th>
            <th>ustaw jako</th>
            <th>usuń bilet z konta użytkownika</th>
          </tr>
        </thead>
        <tr v-for="(item, index) in tickets" :key="item.ticket_id">
          <td>{{ index + 1 }}</td>
          <td>{{ item.ticket_id }}</td>
          <td>{{ item.status_display }}</td>
          <td>{{ item.health_questionnaire ? 'tak' : 'nie' }}</td>
          <td>{{ item.active_qr_code ? 'tak' : 'nie' }}</td>
          <td>{{ item.owner_name }}</td>
          <td>
            <toggle-ticket-adulity-button
              :ticket-id="item.ticket_id"
              :is-child="item.is_child"
              @reload="emitReload"
            />
          </td>
          <td>
            <unbind-ticket-button
              :ticket-id="item.ticket_id"
              :user-id="userID"
              @reload="emitReload"
            />
          </td>
        </tr>
      </table>
    </div>
  </div>
  <div v-else>
    <p>brak przypisanych biletów</p>
  </div>
</template>
<script>
import { User } from '@/api'
import ToggleTicketAdulityButton from '../UserDetails/Buttons/ToggleTicketAdulityButton'
import UnbindTicketButton from '../UserDetails/Buttons/UnbindTicketButton'

export default {
  components: { UnbindTicketButton, ToggleTicketAdulityButton },
  props: {
    tickets: { type: [Array], required: false },
    userID: { type: [String, Number], required: false }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    service() {
      return User
    }
  },
  methods: {
    emitReload() {
      this.$emit('reload')
    }
  }
}
</script>
