<script>
export default {
  data() {
    return {
      availableStatuses: [],
      availableTypes: [],
      filters: {
        available: [
          {
            name: 'Data biletu',
            query_param: 'date',
            date: true
          },
          {
            name: 'Przypisany do użytkownika',
            query_param: 'has_owner',
            options: [
              { label: 'Tak', value: 'true' },
              { label: 'Nie', value: 'false' }
            ]
          },
          {
            name: 'Posiada wypełnioną ankietę zdrowotną',
            query_param: 'health_questionnaire_is_filled_in',
            options: [
              { label: 'Tak', value: 'true' },
              { label: 'Nie', value: 'false' }
            ]
          },
          {
            name: 'Posiada aktywny QRCode',
            query_param: 'has_active_qr_code',
            options: [
              { label: 'Tak', value: 'true' },
              { label: 'Nie', value: 'false' }
            ]
          },
          {
            name: 'Zatrzymać na bramce',
            query_param: 'is_arrested',
            options: [
              { label: 'Tak', value: 'true' },
              { label: 'Nie', value: 'false' }
            ]
          },
          {
            name: 'Status',
            query_param: 'status',
            options: this.setAvailableStatuses
          },
          {
            name: 'Typ',
            query_param: 'ticket_type',
            options: this.setAvailableTypes,
            multiple: true
          },
          {
            name: 'Źródło',
            query_param: 'source',
            options: [
              {
                label: 'System',
                value: 'SYSTEM'
              },
              {
                label: 'eBilet sklep',
                value: 'EBILET_SHOP'
              },
              {
                label: 'ALTER sklep',
                value: 'ALTER_SHOP'
              }
            ]
          },
          {
            name: 'Zaszczepiony',
            query_param: 'health_questionnaire__is_vaccinated',
            options: [
              { label: 'Tak', value: 'true' },
              { label: 'Nie', value: 'false' }
            ]
          },
          {
            name: 'Zrealizowany',
            query_param: 'was_scanned_on_gateway',
            options: [
              { label: 'Tak', value: 'true' },
              { label: 'Nie', value: 'false' }
            ]
          }
        ],
        selected: {}
      }
    }
  },
  methods: {
    setAvailableStatuses(options) {
      this.service
        .availableStatuses()
        .then(response => {
          this.availableStatuses = response.data
          if (options !== undefined) {
            options.push(...response.data)
          }
        })
        .catch(error => {
          console.error(error)
        })
    },
    setAvailableTypes(options) {
      this.service
        .availableTypes()
        .then(response => {
          this.availableTypes = response.data
          if (options !== undefined) {
            options.push(...response.data)
          }
        })
        .catch(error => {
          console.error(error)
        })
    }
  }
}
</script>
