<template>
  <div v-if="ticketId" class="box">
    <el-form-item
      :error="getErrors(errors, ['user_filled_in_paper_health_questionnaire'])"
    >
      <el-checkbox v-model="form.user_filled_in_paper_health_questionnaire">
        Oświadczenie zdrowotne wypełnione na papierze *
      </el-checkbox>
    </el-form-item>
    <el-form-item :error="getErrors(errors, ['is_vaccinated'])">
      <el-checkbox v-model="form.is_vaccinated" size="medium">
        Posiada zaświadczenie o szczepieniu przeciw COVID-19
      </el-checkbox>
    </el-form-item>
    <el-form-item
      :error="getErrors(errors, ['non_field_errors'])"
      label-width="0"
      class="buttons"
    >
      <el-button :loading="loading" @click="cancelForm">Anuluj</el-button>
      <el-button
        type="primary"
        :loading="loading"
        @click="fillInHealthQuestionnaire"
      >
        Wygeneruj
      </el-button>
    </el-form-item>
  </div>
</template>
<script>
import { Ticket } from '@/api'

export default {
  props: {
    ticketId: { type: [String], required: true }
  },
  data() {
    return {
      loading: false,
      form: {
        user_filled_in_paper_health_questionnaire: false,
        is_vaccinated: false
      },
      errors: {}
    }
  },
  computed: {
    service() {
      return Ticket
    }
  },
  methods: {
    cancelForm() {
      this.$emit('cancel')
    },
    fillInHealthQuestionnaire() {
      this.errors = {}
      this.loading = true
      const lookupParams = {
        id: this.ticketId
      }

      this.service
        .fillHealthQuestionnaireManually(lookupParams, this.form)
        .then(() => {
          this.$emit('submit')
        })
        .catch(error => {
          this.errors = error.response.data

          const message = 'Wystąpił błąd podczas wypełniania ankiety.'
          this.$message({
            message: message,
            type: 'error'
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style scoped>
::v-deep span.el-checkbox__label {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
}
.buttons {
  text-align: right;
}
</style>
