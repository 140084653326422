<template>
  <el-dialog
    title="Zarejestruj bilet"
    :center="true"
    width="50vw"
    append-to-body
    :visible.sync="dialogVisible"
    @close="onClose"
  >
    <el-form v-loading="loading" @submit.prevent.native="onSubmit">
      <el-form-item
        required
        label="Identyfikator biletu"
        :error="getErrors(errors, ['ticket'])"
      >
        <el-input v-model="form.ticket" disabled />
      </el-form-item>
      <el-form-item
        required
        label="Imię i naziwsko"
        :error="getErrors(errors, ['full_name'])"
      >
        <el-input
          v-model="form.full_name"
          :disabled="!isSuperUser && preventNameChange"
        />
      </el-form-item>
      <el-form-item
        required
        label="Numer telefonu"
        :error="getErrors(errors, ['phone'])"
      >
        <el-input v-model="form.phone" />
      </el-form-item>
      <el-form-item
        required
        label="Data urodzenia"
        :error="getErrors(errors, ['birth_date'])"
      >
        <el-date-picker
          v-model="form.birth_date"
          :disabled="!isSuperUser && preventBirthDateChange"
          class="fluid"
          value-format="yyyy-MM-dd"
          format="dd.MM.yyyy"
        />
      </el-form-item>
      <button type="primary" class="el-button el-button--primary">
        Dodaj użytkownika do biletu
      </button>
    </el-form>
  </el-dialog>
</template>

<script>
import { ClearingPointTicket, Ticket } from '@/api'

export default {
  data() {
    return {
      dialogVisible: false,
      loading: false,
      ticket: null,
      ticketDetails: {},
      preventNameChange: false,
      preventBirthDateChange: false,
      form: {},
      errors: {}
    }
  },
  computed: {
    isSuperUser() {
      return this.$store.state.userInfo.is_superuser
    }
  },
  methods: {
    openDialog(ticketId) {
      this.form = { ticket: ticketId }
      this.dialogVisible = true
      this.ticket = ticketId
      this.fetchTicketDetails(ticketId)
      this.errors = {}
    },
    fetchTicketDetails(ticketId) {
      const params = { search: ticketId }
      const promise = ClearingPointTicket.fetch({}, { params })
      promise
        .then(res => {
          const ticket = res.data.results[0]
          this.preventNameChange = !!ticket.owner_name
          this.preventBirthDateChange = !!ticket.birth_date
          this.form = {
            ticket: ticketId,
            birth_date: ticket.birth_date,
            full_name: ticket.owner_name
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    onClose() {
      this.dialogVisible = false
      this.ticket = null
    },
    onSubmit() {
      this.loading = true
      Ticket.registerUser(this.form)
        .then(() => {
          this.$message({
            type: 'success',
            message: 'Dodano użytkownika do biletu'
          })
          this.$emit('change')
          this.dialogVisible = false
        })
        .catch(this.errorHandler)
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
