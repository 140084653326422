import FieldBase from '@/components/fields/FieldBase'

export default class extends FieldBase {
  constructor({
    options,
    multiple = false,
    placeholder = 'Wybierz',
    clearable = true,
    filterable = false,
    allowCreate = false,
    disabled = false,
    ...args
  }) {
    super(args)

    const { fieldValue } = this.formData

    const allProfiles = options.map(option => option.value)

    const setProfile = value => {
      allProfiles.forEach(profile => {
        const isSelected = Array.isArray(value)
          ? value.includes(profile)
          : profile === value

        const newValue = isSelected ? {} : null

        this.vue.$set(fieldValue, profile, newValue)
      })
    }

    const profileSelected = allProfiles.find(profile => !!fieldValue[profile])

    const child = {
      component: 'el-select',
      listeners: {
        input: value => setProfile(value)
      },
      attrs: {
        value: profileSelected
      }
    }

    child.attrs = {
      ...child.attrs,
      placeholder,
      clearable,
      multiple,
      filterable,
      allowCreate,
      disabled
    }
    child.children = options.map((option, i) => ({
      component: 'el-option',
      attrs: {
        label: option.label,
        value: option.value,
        key: option.key || i,
        disabled: option.type !== 'HUB'
      }
    }))

    this.children = [child]
  }
}
