<template>
  <div>
    <register-user-to-ticket-modal
      ref="registerUserToTicketModal"
      @change="fetchData"
    />
    <page-header :title="header.title" :add-button="header.addButton" />
    <filters />
    <div class="box">
      <inner-list
        v-if="showList"
        v-loading="loading"
        :results="results"
        @register-ticket="openRegisterUserToTicketModal"
      />
      <el-alert
        v-else
        type="warning"
        :closable="false"
        show-icon
        title="Wprowadź frazę do wyszukiwarki"
        description="Aby znaleźć bilet"
      />
      <div class="list__pagination">
        <pagination :pagination-data="pagination" />
      </div>
    </div>
  </div>
</template>
<script>
import { ClearingPointTicket } from '@/api'
import InnerList from './InnerList'
import Filters from './Filters'
import PaginatedList from '@/layouts/PaginatedList'
import PageHeader from '@/layouts/PageHeader'
import RegisterUserToTicketModal from '@/components/modals/RegisterUserToTicketModal'

export default {
  components: {
    InnerList,
    Filters,
    PageHeader,
    RegisterUserToTicketModal
  },
  extends: PaginatedList,
  data() {
    return {
      service: ClearingPointTicket
    }
  },
  computed: {
    showList() {
      return !!this.$route.query.search
    },
    header() {
      let title = 'Bilety'
      if (this.pagination.total) {
        title = `${title} <sup>(${this.pagination.total})</sup>`
      }
      return {
        addButton: null,
        title
      }
    }
  },
  methods: {
    openRegisterUserToTicketModal(ticketId) {
      this.$refs.registerUserToTicketModal.openDialog(ticketId)
    }
  }
}
</script>
