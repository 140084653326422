import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/store/modules/auth'
import { User, SiteSettings } from '@/api/'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    defaultLanguage: 'pl',
    selectedLanguage: 'pl',
    profiles: [],
    settings: {},
    userInfo: { permissions: [] },
    languages: [
      { code: 'pl', label: 'Polski', flag: '/flags/pl.svg' },
      { code: 'en', label: 'Angielski', flag: '/flags/en.svg' }
    ]
  },
  mutations: {
    setSettings(state, payload) {
      state.settings = payload
    },
    setProfiles(state, payload) {
      state.profiles = payload
    },
    setUserInfo(state, payload) {
      state.userInfo = payload
    }
  },
  actions: {
    fetchCommon() {
      this.dispatch('fetchUserInfo')
    },
    fetchSettings({ commit }) {
      return SiteSettings.get().then(res => {
        commit('setSettings', res.data)
      })
    },
    fetchProfiles({ commit }) {
      return User.fetchUserProfiles().then(res => {
        commit('setProfiles', res.data)
      })
    },
    fetchUserInfo({ commit }) {
      return User.me().then(res => {
        commit('setUserInfo', res.data)
      })
    }
  },
  getters: {
    hasPerm: state => perm => state.userInfo.permissions.includes(perm)
  },
  setters: {},
  modules: {
    auth
  }
})
