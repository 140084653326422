<template>
  <div>
    <el-table :data="results" class="table">
      <el-table-column prop="str" label="Akcja" />
      <el-table-column prop="owner_name" label="Właściciel biletu" />
      <el-table-column prop="is_active" label="Kiedy">
        <template slot-scope="scope">
          {{ formatDate('created', 'DD.MM.YYYY HH:mm')(scope.row) }}
        </template>
      </el-table-column>
      <el-table-column prop="author_str" label="Kto" />
    </el-table>
  </div>
</template>
<script>
export default {
  props: {
    results: Array
  }
}
</script>
<style scoped>
.table {
  margin-bottom: 25px;
}
</style>
