<template>
  <div v-if="ticketId" class="buttons">
    <el-form-item
      :error="getErrors(errors, ['non_field_errors'])"
      label-width="0"
      class="buttons"
    >
      <el-button
        v-if="showCancelButton"
        type="primary"
        :loading="loading"
        @click="cancel"
      >
        Anuluj
      </el-button>
      <el-button type="primary" :loading="loading" @click="generateQRCode">
        {{ label }}
      </el-button>
    </el-form-item>
  </div>
</template>
<script>
import { Ticket } from '@/api'

export default {
  props: {
    activeQRCode: { type: [String], required: false },
    ticketId: { type: [String], required: true },
    regenerateButtonLabel: {
      type: [String],
      required: false,
      default: 'Wygeneruj nowy kod QR'
    },
    generateButtonLabel: {
      type: [String],
      required: false,
      default: 'Wygeneruj QR kod'
    },
    showCancelButton: {
      type: [Boolean],
      required: false,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      errors: {}
    }
  },
  computed: {
    label() {
      return this.activeQRCode
        ? this.regenerateButtonLabel
        : this.generateButtonLabel
    },
    service() {
      return Ticket
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    generateQRCode() {
      this.errors = {}
      this.loading = true
      const lookupParams = {
        id: this.ticketId
      }
      this.service
        .generateQRCode(lookupParams)
        .then(response => {
          this.$emit('submit', response.data)
        })
        .catch(error => {
          this.errors = error.response.data

          const message = 'Wystąpił błąd podczas generowania kodu QR.'
          this.$message({
            message: message,
            type: 'error'
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style scoped>
.buttons {
  text-align: right;
}
</style>
