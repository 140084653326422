<template>
  <form-wrapper @submit="onSubmit">
    <div slot="box" :loading="loading">
      <form-nav
        slot="navbar"
        :loading="loading"
        :redirect-route="redirectRoute"
        :submit-disabled="true"
        @submit="onSubmit"
      >
        <component-compiler
          v-for="(component, i) in getNavButtons()"
          :key="`form-${i}`"
          :content="component"
        />
      </form-nav>
      <el-row :gutter="24" style="padding-top: 20px">
        <el-col :sm="24" :md="17" class="box">
          <component-compiler
            v-for="(component, i) in getFields()"
            :key="`form-${i}`"
            :content="component"
          />
        </el-col>
        <el-col :sm="24" :md="7" class="box">
          <el-button
            v-if="form.owner && hasPermissionToChangeOrViewUser"
            style="margin-bottom: 25px"
            @click="redirectToUserDetails"
          >
            Przejdź do edycji użytkownika
          </el-button>

          <component-compiler
            v-for="(component, i) in getSidebarFields()"
            :key="`form-${i}`"
            :content="component"
          />
        </el-col>
      </el-row>
    </div>
  </form-wrapper>
</template>

<script>
import { Ticket } from '@/api'
import FormMixin from '@/crudBases/form/FormMixin'
import FiltersMixin from '@/views/Tickets/FiltersMixin'
import fields from '@/components/fields/'
import TicketOwnerReadOnlyForm from './TicketOwnerReadOnlyForm'

export default {
  extends: FormMixin,
  mixins: [FiltersMixin],
  data() {
    return {
      form: {},
      redirectRoute: { name: 'Tickets List' }
    }
  },
  computed: {
    service() {
      return Ticket
    },
    hasPermissionToChangeOrViewUser() {
      return (
        this.hasPermission('users.change_user') ||
        this.hasPermission('users.view_user')
      )
    }
  },
  beforeMount() {
    this.fetchOptions()
  },
  methods: {
    redirectToUserDetails() {
      if (this.hasPermission('users.change_user')) {
        this.$router.push({
          name: 'Edit User',
          params: { id: this.form.owner.uuid }
        })
      } else if (this.hasPermission('users.view_user')) {
        this.$router.push({
          name: 'User Details',
          params: { id: this.form.owner.uuid }
        })
      }
    },
    fetchOptions() {
      this.setAvailableStatuses()
      this.setAvailableTypes()
    },
    getFields() {
      return [
        new fields.ReadOnly({
          modelPath: [this, 'form', 'ticket_id'],
          label: 'ID biletu'
        }),
        new fields.Select({
          modelPath: [this, 'form', 'status'],
          label: 'Status',
          condition: !!this.id,
          options: this.availableStatuses,
          disabled: true
        }),
        new fields.ReadOnly({
          modelPath: [this, 'form', 'active_qr_code'],
          label: 'QRCode',
          condition: !!this.form.active_qr_code
        }),
        new fields.Select({
          modelPath: [this, 'form', 'ticket_type'],
          label: 'Typ biletu',
          options: this.availableTypes,
          disabled: true
        }),
        new fields.Date({
          modelPath: [this, 'form', 'date'],
          label: 'Data',
          format: 'yyyy-MM-dd',
          valueFormat: 'yyyy-MM-dd',
          disabled: true
        }),
        new fields.ReadOnly({
          modelPath: [this, 'form', 'owner_name'],
          label: 'Imię i nazwisko właściciela'
        }),
        new fields.ReadOnly({
          condition: this.form.phone,
          modelPath: [this, 'form', 'phone'],
          label: 'Numer tel. właściciela'
        }),
        new fields.Header({
          type: 'h3',
          text: 'Bilet podpięty pod konto użytkownika:',
          condition: !!this.id
        }),
        {
          component: TicketOwnerReadOnlyForm,
          listeners: {
            input: value => this.$set(this.form, 'owner', value)
          },
          attrs: {
            value: this.form.owner,
            errors: (this.errors && this.errors.owner) || {}
          },
          condition: !!this.form.owner
        },
        new fields.Header({
          type: 'h5',
          text: 'bilet nie jest przypisany do żadnego użytkownika',
          condition: !!this.id && !this.form.owner
        })
      ]
    },
    getSidebarFields() {
      return []
    }
  }
}
</script>
