<template>
  <el-button
    v-if="hasPermission('permissions.toggle_ticket_adulity')"
    :loading="loading"
    type="primary"
    @click="confirmToggle(ticketId)"
  >
    {{ buttonText }}
  </el-button>
</template>
<script>
import { Ticket } from '@/api'

export default {
  props: {
    ticketId: String,
    isChild: Boolean
  },
  data() {
    return {
      loading: false,
      texts: {
        true: {
          // change is_child to False
          buttonText: 'Ustaw jako bilet dorosły',
          alertText: 'Bilet zostanie zmieniony na bilet dla dorosłych.',
          successText: 'Zmieniono typ biletu na bilet dla osoby dorosłej.'
        },
        false: {
          // change is_child to True
          buttonText: 'Ustaw jako bilet dziecięcy',
          alertText: 'Bilet zostanie zmieniony na bilet dla dzieci.',
          successText: 'Zmieniono typ biletu na bilet dla dziecka.'
        }
      }
    }
  },
  computed: {
    buttonText() {
      return this.texts[this.isChild].buttonText
    },
    alertText() {
      return this.texts[this.isChild].alertText
    },
    successText() {
      return this.texts[this.isChild].successText
    }
  },
  methods: {
    confirmToggle() {
      this.$confirm(this.alertText, 'Ostrzeżenie', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Anuluj',
        type: 'warning'
      }).then(() => {
        this.toggleTicket(this.ticketId)
      })
    },
    toggleTicket() {
      this.loading = true
      const lookupParams = {
        id: this.ticketId
      }
      Ticket.toggleTicketAdulity(lookupParams)
        .then(() => {
          this.$message({
            message: this.successText,
            type: 'success'
          })
        })
        .catch(error => {
          if (error.response.status !== 304) {
            const message = 'Wystąpił błąd. Spróbuj ponownie'
            this.$message({
              message: message,
              type: 'error'
            })
          }
        })
        .finally(() => {
          this.$emit('reload')
          this.loading = false
        })
    }
  }
}
</script>
