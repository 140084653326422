<template>
  <el-form>
    <el-row>
      <el-col :md="24" :lg="16">
        <el-row :gutter="24">
          <el-col :md="12" :sm="24">
            <el-form-item label="Imię i nazwisko">
              <el-input v-model="user.full_name" disabled />
            </el-form-item>
          </el-col>
          <el-col :md="12" :sm="24">
            <el-form-item label="Data urodzenia">
              <el-input v-model="user.birth_date" disabled />
            </el-form-item>
          </el-col>
          <el-col :md="12" :sm="24">
            <el-form-item label="Numer telefonu">
              <el-input v-if="editMode" v-model="form.phone" />
              <el-input v-else v-model="user.phone" disabled />
            </el-form-item>
          </el-col>
          <el-col v-if="canEdit" :md="12" :sm="24">
            <div style="margin-top: 20px">
              <el-button
                v-loading="loading"
                type="primary"
                class="margin-right-15"
                @click="editMode ? savePhoneNumber() : editPhoneNumber()"
                v-text="editMode ? 'Zapisz zmiany' : 'Zmień numer'"
              />
              <el-button
                v-if="editMode"
                type="info"
                @click="cancelEdit"
                v-text="'Anuluj'"
              />
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import { User } from '@/api'

export default {
  props: {
    user: Object
  },
  data() {
    return {
      editMode: false,
      loading: false,
      form: {}
    }
  },
  computed: {
    canEdit() {
      return this.$store.state.userInfo.permissions.includes(
        'permissions.change_user_phone'
      )
    }
  },
  methods: {
    cancelEdit() {
      this.form = {}
      this.editMode = false
    },
    editPhoneNumber() {
      this.form = { phone: this.user.phone }
      this.editMode = true
    },
    savePhoneNumber() {
      this.loading = true

      User.changePhone({
        userId: this.user.uuid,
        phone: this.form.phone
      })
        .then(() => {
          this.$emit('change')
          this.$message.success('Zapisano zmiany')
          this.editMode = false
        })
        .catch(err => {
          const error = err.response?.data?.phone.join(', ')
          const defaultMsg =
            'Nie udało się zmienić numeru. Upewnij się, że jest poprawny.'
          this.$message({
            message: error || defaultMsg,
            type: 'error'
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
