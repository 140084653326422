<template>
  <div>
    <ticket-logs-modal ref="ticketLogsModal" />
    <tickets-table
      :tickets="tickets"
      @open-add-qr-code-modal="
        ticket => $emit('open-add-qr-code-modal', ticket)
      "
      @open-history-modal="ticketId => $emit('open-history-modal', ticketId)"
      @open-gate-logs-modal="
        ticketId => $emit('open-gate-logs-modal', ticketId)
      "
    />
  </div>
</template>
<script>
import TicketsTable from '@/components/tables/TicketsTable'
import TicketLogsModal from '@/components/modals/TicketLogsModal'
export default {
  components: {
    TicketLogsModal,
    TicketsTable
  },
  props: {
    user: Object
  },
  computed: {
    tickets() {
      return this.user.tickets.filter(ticket => ticket.is_child)
    }
  }
}
</script>
