<template>
  <div>
    <el-dialog
      title="Zmień kolejność"
      width="50vw"
      append-to-body
      :visible.sync="dialogFormVisible"
    >
      <draggable v-model="orderedOptions">
        <el-card v-for="(item, i) in orderedOptions" :key="`${i}`">
          <i class="el-icon-more" style="transform: rotate(90deg)" />
          {{ item.label }}
        </el-card>
      </draggable>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelForm()">Anuluj</el-button>
        <el-button type="primary" @click="submitForm()"> Zatwierdź </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
import { clone } from 'lodash'

export default {
  components: {
    draggable
  },
  props: ['options'],
  data() {
    return {
      dialogFormVisible: false,
      order: []
    }
  },
  computed: {
    orderedOptions: {
      get() {
        const optionByValue = value =>
          this.options.find(option => option.value === value)
        return this.order.map(optionByValue)
      },
      set(value) {
        this.order = value.map(option => option.value)
      }
    }
  },
  methods: {
    openDialog(value) {
      this.order = clone(value)
      this.dialogFormVisible = true
    },
    cancelForm() {
      this.dialogFormVisible = false
      this.$emit('cancel')
    },
    submitForm() {
      this.dialogFormVisible = false
      this.$emit('changeOrder', this.order)
    }
  }
}
</script>
<style lang="scss" scoped>
.el-card {
  cursor: pointer;
}
</style>
