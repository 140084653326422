<template>
  <div>
    <page-header :title="header.title" :add-button="header.addButton" />
    <filters />
    <div class="box">
      <ticket-logs-table v-loading="loading" :results="results" />
      <div class="list__pagination">
        <pagination :pagination-data="pagination" />
      </div>
    </div>
  </div>
</template>
<script>
import { TicketLog } from '@/api'
import Filters from './Filters'
import PaginatedList from '@/layouts/PaginatedList'
import PageHeader from '@/layouts/PageHeader'
import TicketLogsTable from '@/components/tables/TicketLogsTable'

export default {
  components: {
    TicketLogsTable,
    Filters,
    PageHeader
  },
  extends: PaginatedList,
  data() {
    return {
      service: TicketLog
    }
  },
  computed: {
    header() {
      let title = 'Aktywność'
      if (this.pagination.total) {
        title = `${title} <sup>(${this.pagination.total})</sup>`
      }
      return {
        addButton: null,
        title
      }
    }
  },
  created() {
    this.$store.dispatch('fetchProfiles')
  }
}
</script>
