<script>
import { Ticket } from '@/api'
import ListMixin from '@/crudBases/list/ListMixin'
import FiltersMixin from '@/views/Tickets/FiltersMixin'
import { keyBy } from 'lodash'
import DownloadTicketsInCsvButton from '@/components/buttons/DownloadTicketsInCsvButton'

export default {
  components: {
    DownloadTicketsInCsvButton
  },
  mixins: [ListMixin, FiltersMixin],
  data() {
    return {
      editRouteName: 'Ticket Details',
      addRoute: null,
      verboseName: 'Bilet',
      verboseNamePlural: 'Bilety',
      lookupKwargKey: 'id',
      lookupKwargValue: 'ticket_id',
      listFields: [
        { prop: 'ticket_id', label: 'id', width: 80 },
        {
          prop: 'status',
          label: 'status',
          formatter: this.statusLabelFormatter
        },
        {
          prop: 'ticket_typ',
          label: 'typ',
          formatter: this.ticketTypeLabelFormatter
        },
        {
          prop: 'owner_name',
          label: 'imię i nazwisko kupującego'
        },
        {
          prop: 'phone',
          label: 'telefon kupującego'
        }
      ]
    }
  },
  computed: {
    service() {
      return Ticket
    },
    availableStatusesMap() {
      return keyBy(this.availableStatuses, 'value')
    },
    availableTypesMap() {
      return keyBy(this.availableTypes, 'value')
    }
  },
  beforeMount() {
    this.fetchOptions()
  },
  methods: {
    getRouteName(route) {
      return `${route.meta.label} <sup>(${this.count})</sup>`
    },
    fetchOptions() {
      this.setAvailableStatuses()
      this.setAvailableTypes()
    },
    getLabel(map, value) {
      return map[value] ? map[value].label : value
    },
    statusLabelFormatter(instance) {
      return this.getLabel(this.availableStatusesMap, instance.status)
    },
    ticketTypeLabelFormatter(instance) {
      return this.getLabel(this.availableTypesMap, instance.ticket_type)
    },
    actions(scope) {
      return [
        {
          component: 'router-link',
          attrs: {
            to: this.getEditRoute(scope.row)
          },
          children: [this.getButtonComponent({ icon: 'fa fa-edit' })]
        },
        ...this.additionalActions(scope)
      ]
    }
  }
}
</script>
