<template>
  <el-table
    ref="table"
    :data="tickets"
    empty-text="Brak biletów"
    @row-click="expandRow"
  >
    <el-table-column type="expand">
      <template slot-scope="props">
        <div class="buttons">
          <print-q-r-code-button
            v-if="props.row.active_qr_code"
            :disabled="!isValidTicket(props.row)"
            :qr-code-id="props.row.active_qr_code"
          />
          <el-button
            v-if="hasPermission('tickets.add_qrcode')"
            type="info"
            :disabled="!isValidTicket(props.row)"
            @click="openAddQRCodeModal(props.row)"
          >
            {{
              !!props.row.active_qr_code
                ? 'Wygeneruj nowy kod QR'
                : 'Wygeneruj QR kod'
            }}
          </el-button>
        </div>
      </template>
    </el-table-column>
    <el-table-column label="" width="60">
      <template slot-scope="scope">
        <span v-if="scope.row.active_qr_code" class="borderedQR">QR</span>
      </template>
    </el-table-column>
    <el-table-column prop="ticket_id" label="Numer biletu" sortable />
    <el-table-column prop="ticket_type_display" label="Typ biletu" sortable />
    <el-table-column label="Data wydarzenia" sortable sort-by="date">
      <template slot-scope="scope">
        {{ formatDate('date', 'DD.MM.YYYY')(scope.row) }}
      </template>
    </el-table-column>
    <el-table-column label="Oświadczenie zdrowotne">
      <template slot-scope="scope">
        {{ scope.row.health_questionnaire ? 'Jest' : 'Brak' }}
      </template>
    </el-table-column>
    <el-table-column
      v-if="showOwnerName"
      prop="owner_name"
      label="Właściciel biletu"
      sortable
    />
    <el-table-column label="Status">
      <template slot-scope="scope">
        {{ scope.row.status_display }}
      </template>
    </el-table-column>
    <el-table-column prop="device_name" label="Urządzenie" />
    <el-table-column label="">
      <template slot-scope="scope">
        <el-dropdown>
          <span class="el-dropdown-link">
            <i class="el-icon-more" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-if="hasPermission('permissions.clearingpoint_ticketlogs')"
              @click.native="openHistoryModal(scope.row.ticket_id)"
              >Historia operacji</el-dropdown-item
            >
            <el-dropdown-item
              v-if="hasPermission('gate_entry_logs.view_gateentrylog')"
              @click.native="openGateLogsModal(scope.row.ticket_id)"
              >Historia wejść/wyjść</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import PrintQRCodeButton from '@/components/PrintQRCodeButton'

export default {
  components: {
    PrintQRCodeButton
  },
  props: {
    tickets: Array,
    showOwnerName: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    openAddQRCodeModal(ticket) {
      this.$emit('open-add-qr-code-modal', ticket)
    },
    openHistoryModal(ticketId) {
      this.$emit('open-history-modal', ticketId)
    },
    openGateLogsModal(ticketId) {
      this.$emit('open-gate-logs-modal', ticketId)
    },
    expandRow() {
      // this.$refs.table.toggleRowExpansion(yy.rowSpan)
    },
    isValidTicket(ticket) {
      return ticket.status === 'valid'
    }
  }
}
</script>
<style scoped>
.buttons {
  text-align: right;
}

.el-dropdown-link {
  cursor: pointer;
  font-size: 18px;
}

.borderedQR {
  border-width: 3px;
  text-align: center;
  border-style: groove;
  font-style: normal;
  font-weight: normal;
  color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 27px;
  height: 25px;
}
</style>
