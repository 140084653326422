<template>
  <el-table-column
    :key="field.prop"
    :width="field.width"
    :sortable="field.sortable ? 'custom' : false"
    :label="field.label"
    :prop="field.prop"
  >
    <template slot-scope="scope" class="pull-right">
      <strong>{{ scope.row.source_display }}</strong> <br />
      {{ scope.row.ticket_id }}
    </template>
  </el-table-column>
</template>

<script>
export default {
  props: {
    field: Object
  }
}
</script>
