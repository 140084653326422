import FieldBase from './FieldBase'

export default class extends FieldBase {
  constructor({ help, disabled, ...args }) {
    super(args)
    const child = this.getComponentWithVModel('el-switch')
    child.attrs = {
      ...child.attrs,
      disabled
    }
    this.children = [child]

    if (help) {
      this.children.push({
        component: 'p',
        innerText: help,
        attrs: { class: 'field-info' }
      })
    }
  }
}
