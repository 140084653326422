<template>
  <el-button
    icon="el-icon-download"
    type="primary"
    :loading="loading"
    @click="onClick()"
    >Pobierz listę w CSV</el-button
  >
</template>
<script>
import { Ticket } from '@/api'
import { downloadCSVResponse } from '@/common/js/functions'

export default {
  data() {
    return {
      loading: false,
      showDialog: false
    }
  },
  computed: {
    params() {
      return this.$route.query
    }
  },
  methods: {
    onClick() {
      this.loading = true
      Ticket.downloadCsv(this.params)
        .then(res => {
          downloadCSVResponse(res.data, 'tickets.csv')
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
