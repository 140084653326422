<template>
  <div></div>
</template>
<script>
export default {
  created() {
    const profiles = this.$store.state.userInfo.profiles || []
    let route = this.$route.query.next || { name: 'Tickets List' }
    if (
      profiles.includes('ClearingPoint') ||
      profiles.includes('SuperVisorCP')
    ) {
      route = { name: 'ClearingPoint Tickets List' }
    }
    this.$router.push(route)
  }
}
</script>
