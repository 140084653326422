<template>
  <div style="padding-left: 25px">
    <component-compiler
      v-for="(component, i) in getFields()"
      :key="`form-${i}`"
      :content="component"
    />
  </div>
</template>
<script>
import FieldsHelper from '@/mixins/form/FieldsHelper'
import fields from '@/components/fields/'
import ComponentCompiler from '@/components/ComponentCompiler'

export default {
  components: {
    ComponentCompiler
  },
  mixins: [FieldsHelper],
  methods: {
    getFields() {
      return [
        new fields.ReadOnly({
          modelPath: [this, 'form', 'full_name'],
          label: 'Imię i naziwsko'
        }),
        new fields.ReadOnly({
          modelPath: [this, 'form', 'phone'],
          label: 'Numer telefonu'
        }),
        new fields.Switch({
          modelPath: [this, 'form', 'is_active'],
          label: 'Aktywne konto',
          disabled: true
        }),
        new fields.Date({
          modelPath: [this, 'form', 'date_joined'],
          label: 'Data dołączenia',
          format: 'yyyy-MM-dd HH:mm:ss',
          disabled: true
        }),
        new fields.Date({
          modelPath: [this, 'form', 'last_login'],
          label: 'Data ostatniego logowania',
          format: 'yyyy-MM-dd HH:mm:ss',
          disabled: true
        })
      ]
    }
  }
}
</script>
