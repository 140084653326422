<template>
  <el-button
    v-if="hasPermission('permissions.print_qrcode')"
    icon="el-icon-printer"
    type="warning"
    :loading="loading"
    @click="onClick()"
    >Wydrukuj kod QR</el-button
  >
</template>
<script>
import { Ticket } from '@/api'
import print from 'print-js'

export default {
  props: {
    qrCodeId: String
  },
  data() {
    return {
      loading: false,
      showDialog: false
    }
  },
  methods: {
    onClick() {
      this.loading = true
      Ticket.downloadQRCodeToPrint(this.qrCodeId)
        .then(res => {
          const base64 = this.resToBase64(res)
          this.printPdfInBase64(base64)
        })
        .finally(() => {
          this.loading = false
        })
    },
    resToBase64(res) {
      return Buffer.from(res.data, 'binary').toString('base64')
    },
    printPdfInBase64(base64) {
      print({ printable: base64, type: 'pdf', base64: true })
    }
  }
}
</script>
