<template>
  <el-button
    v-if="$store.getters.hasPerm('permissions.request_gate_entry_logs')"
    icon="el-icon-refresh"
    type="primary"
    :loading="loading"
    @click="onClick()"
    >Zaaktualizuj informacje</el-button
  >
</template>
<script>
import { GateEntryLog } from '@/api'

export default {
  props: {
    ticketId: String
  },
  data() {
    return {
      loading: false,
      showDialog: false
    }
  },
  methods: {
    onClick() {
      this.loading = true
      GateEntryLog.requestSynchronization(this.ticketId)
        .then(() => {
          this.$message.success(
            'Historia wejść/wyjść powinna zostać pobrana w ciągu paru minut'
          )
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
