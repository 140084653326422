<template>
  <div>
    <el-table
      :data="results"
      class="table"
      empty-text="Nie znaleziono biletów.
        Pamiętaj o wprowadzeniu pełnego numeru biletu, imienia i nazwiska lub numeru telefonu."
      @row-click="onRowClick"
    >
      <el-table-column label="" width="60">
        <template slot-scope="scope">
          <span v-if="scope.row.active_qr_code" class="borderedQR">QR</span>
        </template>
      </el-table-column>
      <el-table-column prop="ticket_id" label="Numer biletu" width="180" />
      <el-table-column prop="owner_name" label="Właściciel biletu" />
      <el-table-column label="Status">
        <template slot-scope="scope">
          {{ scope.row.status_display }}
        </template>
      </el-table-column>
      <el-table-column prop="ticket_type_display" label="Typ biletu" />
      <el-table-column label="Data wydarzenia" width="140">
        <template slot-scope="scope">
          {{ formatDate('date', 'DD.MM.YYYY')(scope.row) }}
        </template>
      </el-table-column>
      <el-table-column label="Akcje" align="right">
        <template slot-scope="scope">
          <router-link
            v-if="scope.row.owner"
            :to="{ name: 'User Details', params: { id: scope.row.owner_uuid } }"
          >
            <el-link type="primary">Więcej</el-link>
          </router-link>
          <el-link
            v-else
            type="primary"
            :disabled="scope.row.ticket_type === 'COMPS_VIP'"
            @click="$emit('register-ticket', scope.row.ticket_id)"
            >Zarejestruj bilet</el-link
          >
          <el-tooltip placement="top">
            <div slot="content">Nie można zarejestrować biletu dla VIP</div>
            <i
              v-if="scope.row.ticket_type === 'COMPS_VIP'"
              class="el-icon el-icon-question"
              style="margin-left: 15px"
            />
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  props: {
    results: Array
  },
  methods: {
    onRowClick(ticket) {
      if (ticket.owner) {
        this.$router.push({
          name: 'User Details',
          params: { id: ticket.owner_uuid }
        })
      }
    }
  }
}
</script>
<style scoped>
.table {
  margin-bottom: 25px;
}

.table >>> .el-table__row {
  cursor: pointer;
}

.borderedQR {
  border-width: 3px;
  text-align: center;
  border-style: groove;
  font-style: normal;
  font-weight: normal;
  color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 27px;
  height: 25px;
}
</style>
