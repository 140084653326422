import axios from 'axios'
import Service, { prepareURL } from '@/api/service'

axios.defaults.baseURL = process.env.VUE_APP_API_URL

/*
    using '...Service('/url/:id/')' in object provides methods:
        fetch
        get
        delete
        put
        save
 */

export const SiteSettings = {
  update: payload => {
    return axios.put('/admin/core/settings/', payload)
  },
  get: payload => {
    return axios.get('/admin/core/settings/', payload)
  }
}

export const Auth = {
  login(payload) {
    return axios.post('/public/users/auth/login/', payload)
  },
  sendSMSCode(payload) {
    return axios.post('/public/sms-auth/login/send-code/', payload)
  }
}

export const User = {
  ...Service('/admin/users/:id/'),
  fetchUserProfiles() {
    const url = '/admin/users/profiles/'
    return axios.get(url)
  },
  bindUserToTicket: (urlParams, payload) => {
    const url = '/admin/users/:id/bind-user-to-ticket/'
    return axios.post(prepareURL(url, urlParams), payload)
  },
  unbindUserFromTicket: (urlParams, payload) => {
    const url = '/admin/users/:id/unbind-user-from-ticket/'
    return axios.post(prepareURL(url, urlParams), payload)
  },
  me() {
    const url = '/admin/users/me/'
    return axios.get(url)
  },
  changePhone({ userId, phone }) {
    const url = `/admin/users/${userId}/change-phone/`
    return axios.put(url, { phone })
  }
}

export const Ticket = {
  ...Service('/admin/tickets/:id/'),
  availableStatuses() {
    return axios.get('/admin/tickets/available-statuses/')
  },
  availableSources() {
    return axios.get('/admin/tickets/available-sources/')
  },
  availableTypes() {
    return axios.get('/admin/tickets/available-types/')
  },
  generateQRCode: urlParams => {
    const url = '/admin/tickets/:id/generate-qr-code/'
    return axios.post(prepareURL(url, urlParams), {})
  },
  fillHealthQuestionnaireManually: (urlParams, payload) => {
    const url = '/admin/tickets/:id/fill-health-questionnaire-manually/'
    return axios.post(prepareURL(url, urlParams), payload)
  },
  downloadQRCodeToPrint(qrCodeId) {
    const url = `/admin/tickets/print-qr-code/${qrCodeId}/`
    return axios.get(url, {
      responseType: 'arraybuffer'
    })
  },
  downloadCsv(params) {
    const url = `/admin/tickets/csv/`
    return axios.get(url, {
      params,
      responseType: 'blob'
    })
  },
  registerUser(payload) {
    const url = '/clearingpoint/tickets/register-user/'
    return axios.post(url, payload)
  },
  toggleTicketAdulity(urlParams) {
    const url = '/admin/tickets/:id/toggle-ticket-adulity/'
    return axios.post(prepareURL(url, urlParams))
  }
}

export const ClearingPointTicket = {
  ...Service('/clearingpoint/tickets/:id/')
}

export const TicketLog = {
  ...Service('/admin/ticket-logs/:id/')
}

export const ClearingPointTicketLog = {
  fetch: payload => {
    return axios.get('/clearingpoint/ticket-logs/', payload)
  }
}

export const GateEntryLog = {
  ...Service('/admin/gate-entry-logs/:id/'),
  requestSynchronization(ticketId) {
    const url = `/admin/gate-entry-logs/request-synchronization/${ticketId}/`
    return axios.post(url)
  }
}
