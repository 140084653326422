<template>
  <div v-if="userID" class="box">
    <el-form-item label="Numer biletu" :error="getErrors(errors, ['ticket'])">
      <el-input
        v-model="form.ticket"
        type="text"
        :disabled="loading"
        auto-complete="off"
        placeholder="numer biletu"
      >
      </el-input>
    </el-form-item>
    <el-form-item
      :error="getErrors(errors, ['non_field_errors'])"
      label-width="0"
    >
      <el-button :loading="loading" @click="bindUserToTicket">
        Przypisz bilet
      </el-button>
    </el-form-item>
  </div>
</template>
<script>
import { User } from '@/api'

export default {
  props: {
    userID: { type: [String, Number], required: false }
  },
  data() {
    return {
      loading: false,
      form: {
        ticket: ''
      },
      errors: {}
    }
  },
  computed: {
    service() {
      return User
    }
  },
  methods: {
    bindUserToTicket() {
      this.errors = {}
      this.loading = true
      const lookupParams = {
        id: this.userID
      }

      this.service
        .bindUserToTicket(lookupParams, this.form)
        .then(() => {
          this.$emit('reload')

          const message = 'Przypisano użytkownika do biletu.'
          this.$message({
            message: message,
            type: 'success'
          })
          this.form = {}
        })
        .catch(error => {
          this.errors = error.response.data

          const message =
            'Wystąpił błąd podczas przypisywania użytkownika do biletu.'
          this.$message({
            message: message,
            type: 'error'
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
