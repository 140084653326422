<template>
  <div>
    <el-table :data="results" class="table">
      <el-table-column prop="event_type_display" label="Wejście/wyjście" />
      <el-table-column prop="gate_name" label="Bramka" />
      <el-table-column label="Kiedy">
        <template slot-scope="scope">
          {{ formatDate('event_date', 'DD.MM.YYYY HH:mm')(scope.row) }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  props: {
    results: Array
  }
}
</script>
<style scoped>
.table {
  margin-bottom: 25px;
}
</style>
