<template>
  <el-dialog
    title="Historia aktywności w bilecie"
    width="50vw"
    append-to-body
    :visible.sync="dialogVisible"
    @close="onClose"
  >
    <ticket-logs-table v-loading="loading" :results="logs" />
  </el-dialog>
</template>

<script>
import { ClearingPointTicketLog } from '@/api'
import TicketLogsTable from '@/components/tables/TicketLogsTable'

export default {
  components: { TicketLogsTable },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      logs: []
    }
  },
  methods: {
    showModal(ticketId) {
      this.dialogVisible = true
      this.ticketId = ticketId
      this.fetchData()
    },
    fetchData() {
      this.loading = true
      const params = { ticket: this.ticketId, page_size: 'max' }
      ClearingPointTicketLog.fetch({ params })
        .then(res => {
          this.logs = res.data.results
        })
        .finally(() => {
          this.loading = false
        })
    },
    onClose() {
      this.ticketId = null
      this.logs = []
    }
  }
}
</script>

<style scoped></style>
